<template>
  <KAvatar
    :alt="alt || user.fullName"
    class="mr-1"
    :color="colorPallet[colorPalletIndex]"
    :size="size"
    :src="avatarUrl"
    :text="charAvatar"
  ></KAvatar>
</template>

<script setup lang="ts">
import type { User } from '~/types/user'

const props = withDefaults(
  defineProps<{
    user: User
    alt?: string
    size?: number | string
    start?: boolean
  }>(),
  {
    start: false,
    size: 32,
    alt: '',
  }
)
const colorPallet = [
  'red',
  'pink',
  'purple',
  'deep-purple',
  'indigo',
  'blue',
  'light-blue',
  'cyan',
  'teal',
  'green',
  'light-green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deep-orange',
]

const colorPalletIndex = computed(() => {
  const name = props.user.fullName
  return (
    Array.from(name).reduce((acc, char) => acc + char.charCodeAt(0), 0) %
    colorPallet.length
  )
})

const avatarUrl = computed(() => {
  const size: number =
    typeof props.size === 'number' ? props.size : parseInt(props.size)
  if (size <= 32) {
    return props.user.image['32x32']
  } else if (size <= 64) {
    return props.user.image['64x64']
  } else {
    return props.user.image['100x100']
  }
})
const charAvatar = computed(() => {
  const lastName = props.user.lastName || ''
  const firstName = props.user.firstName || ''
  if (lastName.charCodeAt(0) <= 127 && firstName.charCodeAt(0) <= 127) {
    return `${firstName.slice(0, 1).toUpperCase()}${lastName.slice(0, 1).toUpperCase()}`
  } else if (lastName) {
    const c = lastName.codePointAt(0)
    return c ? String.fromCodePoint(c) : ''
  } else {
    return ''
  }
})
</script>

<style scoped></style>
